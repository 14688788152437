<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :hide-overlay="$vuetify.breakpoint.smAndDown"
    >

      <v-card>
        <v-form  v-model="valid" ref="form">

          <v-toolbar
              dark
              color="brown"
              class="color-gradient-1"
              dense
          >
            <v-btn
                icon
                dark
                @click="closeForm"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ lang==='id'? title : 'Province' }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM' ">EDIT </span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM' "> BARU</span>

            <span class="ml-2 mr-2 font-weight-medium grey--text" v-show="isItemModified"> | </span>
            <v-chip
                class="ma-2"
                color="warning"
                outlined
                x-small
                v-show="isItemModified"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              modfied
            </v-chip>

            <v-spacer></v-spacer>
            <v-toolbar-items >
              <v-btn
                  dark
                  text
                  @click="save"
                  :disabled="!valid || isItemModified===false"
                  class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-title>

          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    sm="4"
                    md="4"
                >
                  <v-text-field
                      v-model="itemModified.kode1"
                      label="Kode"
                      :rules="rulesLenght"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="itemModified.description"
                      :rules="rulesNotEmtpy"
                      :label="lang==='id'? 'Nama Provinsi': 'Name of Province'"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="2"
                    md="2"
                >
                  <v-switch
                      v-model="itemModified.statusActive"
                      :label="itemModified.statusActive?'Aktif':'Non-Aktif'"
                      x-small
                      class="pa-3"
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="12"
                >
                  <v-autocomplete
                      v-model="itemModified.fdivisionBean"
                      :items="itemsFDivision"
                      :rules="rulesNotEmtpy"
                      item-value="id"
                      item-text="description"
                      auto-select-first
                      dense
                      chips
                      deletable-chips
                      color="blue-grey lighten-2"
                      label="Divisi/Pemilik Data"
                      :hint="`Divisi/Pemilik Data : ${lookupFDivision(itemModified.fdivisionBean)}`"
                      persistent-hint
                      single-line
                  ></v-autocomplete>
                </v-col>
              </v-row>

           </v-container>
          </v-card-text>


          <v-card-actions>
            <v-chip
                class="ml-4"
                color="error"
                outlined
                close
                small
                v-show="formDialogOptions.errorMessage"
            >
              {{formDialogOptions.errorMessage}}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="closeForm"
                class="hidden-sm-and-down"
            >
            {{lang==='id'? 'Batal': 'Cancel'}}
            </v-btn>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="save"
                :disabled="!valid || isItemModified===false"
                class="hidden-sm-and-down"
            >
            {{lang==='id'? 'Simpan': 'Save'}}
            </v-btn>
          </v-card-actions>
        </v-form>

      </v-card>


      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>

      <UploadImageDialog
          ref="refUploadDialog"
          @eventUploadSuccess="completeUploadSuccess"
      >
      </UploadImageDialog>
      <v-snackbar
          v-model="snackbar"
      >
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>


    </v-dialog>
  </div>

</template>

<script>
import FRegionService from '../../../services/apiservices/f-region-service';

import CloseConfirmDialog from "../../../components/utils/CloseConfirmDialog";
import FormMode from "../../../models/form-mode";
import FRegion from "../../../models/f-region";
import FileService from "../../../services/apiservices/file-service";
import UploadImageDialog from "../../../components/utils/UploadImageDialog";

export default {
  components: { CloseConfirmDialog, UploadImageDialog },
  props:{
    formMode: String,
  },
  data() {
    return {
      title: 'ProVinsi',
      snackBarMesage: '',
      snackbar: false,

      imageOverlay: false,

      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },

      selectedItemIndex: -1,
      itemDefault: '',
      itemModified: new FRegion(0, '', ''),
      selectFDivision: {id: 0, kode1: '', description: '' },
      itemsFDivision: [],

      valid: false,
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],

      transparent: 'rgba(255, 255, 255, 0)',

    }
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault)
      const modifiedItem = JSON.stringify(this.itemModified)
      return defaultItem !== modifiedItem
    },
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },

  },
  watch: {
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFSectorType()
        }
      }
    },
  },

  methods: {

    showDialogUpload(){
      if (this.itemModified.kode1 !==undefined &&
          this.itemModified.description !==undefined &&
          this.itemModified.fdivisionBean !==undefined){

        if (this.itemModified.id===0){
          // this.$emit('eventSaveItemWithoutClose', false)
          this.saveCreateOnly()
        }
        this.$refs.refUploadDialog.showDialog()

      }else{
        this.snackBarMesage = 'Kode, Deskripsi dan Divisi harus diisi dahulu'
        this.snackbar = true
      }

    },

    completeUploadSuccess: function (val){

      // console.log(val)
      //Hapus Dulu yang lama
      console.log(this.itemModified.avatarImage)

      if (this.itemModified.avatarImage !==undefined && this.itemModified.avatarImage !== '' ) {
        FileService.deleteImage(this.itemModified.avatarImage).then(
            response=>{
              console.log(response.data)
            },
            error => {
              console.log(error.response)
            }
        )
      }

      if (val.fileName !==""){
        this.$refs.refUploadDialog.closeDialog()
        this.itemModified.avatarImage = val.fileName
        this.saveUpdateOnly()
      }

    },

    showDialog(selectedIndex, item, itemsFDivision) {
      // console.log("show UserDialog " + item.id)
      // console.log(this.formMode)
      this.dialogShow = !this.dialogShow
      if (selectedIndex >-1) {
        this.selectedIndex = selectedIndex
        this.initializeEditMode(item)
      }else {
        this.itemDefault =  new FRegion(0, '', ''),
        this.itemModified =  new FRegion(0, '', ''),
        this.selectedIndex = -1
      }

      this.itemsFDivision = itemsFDivision

    },
    setDialogState(value){
      this.dialogShow =value
    },
    save(){
      if (this.isItemModified===false){
        //Close aja
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
        return
      }
      if (this.$refs.form.validate()){
        if (this.formMode===FormMode.EDIT_FORM) {
          FRegionService.updateFRegion(this.itemModified).then(
              () => {
                // console.log(response.data)
                this.$emit('eventFromFormDialogEdit', this.itemModified)
              },
              error => {
                // console.log(error);
                this.formDialogOptions.errorMessage = error.response.data.message
              }
          )
        }else {
          FRegionService.createFRegion(this.itemModified).then(
              response =>{
                this.$emit('eventFromFormDialogNew', response.data)
              },
              error => {
                this.formDialogOptions.errorMessage = error.response.data.message
              }
          )
        }
      }
    },
    saveCreateOnly(){
      FRegionService.createFRegion(this.itemModified).then(
          response =>{
            /**
             * dipaksa Save dan Update Dahulu
             */
            // this.initializeEditMode(response.data)
            this.$emit('update:formMode', FormMode.EDIT_FORM)
            this.itemModified.id = response.data.id
          },
          error => {
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    saveUpdateOnly(){
      FRegionService.updateFRegion(this.itemModified).then(
          () => {
          },
          error => {
            // console.log(error);
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    closeForm(){
      if (! this.isItemModified){
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
      }else {
        this.$refs.refCloseConfirmDialog.showDialog(" Sudah terdapat modifikasi data", "Tetap tutup dan reset perubahan?")
      }
    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },
    initializeEditMode(item){
      // this.itemDefault = item
      // this.itemModified = item

      this.formDialogOptions.errorMessage = ''

      FRegionService.getFRegionById(item.id).then(
          response =>{
            // console.log(response.data)
            this.itemDefault = Object.assign({}, response.data)
            this.itemModified = response.data
          },
          error =>{
            console.log(error)
          }
      )
    },
    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return `${str[0].description} (${str[0].kode1})`
      }else {
        return '-'
      }
    },
    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_medium(item.avatarImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/no_image_available.jpeg'
    },

  }

}
</script>

<style scoped>

  .card-hover-opacity{
    opacity: 0.4;
  }
  .card-not-hover-opacity{
    opacity: 1;
    transition: opacity .4s ease-in-out;
  }

  .show-btns {
    color: blue !important;
    opacity: 1;
  }

</style>