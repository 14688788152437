<template>
  <v-card class="elevation-0">

    <v-card-title>
      {{ titleComputed }}
      <v-spacer></v-spacer>


      <v-text-field
          v-on:keyup.enter="searchOnEnter"
          v-on:blur="searchOnEnter"
          append-icon="mdi-magnify"
          hint="Kode, Deskripsi (press ENTER to search)"
          label="Cari Kata Kunci"
          class="ml-2"
      ></v-text-field>
      <v-btn
          @click="showFilter = !showFilter"
          icon fab
          color="blue"
          small
          class="ml-2"
      >
        <v-icon>mdi-filter</v-icon>
      </v-btn>

    </v-card-title>

    <v-expand-transition>
      <v-card class="ml-2 mr-2 elevation-0 grey lighten-4 " v-show="showFilter===true">
        <v-card-text>
          <v-row>
            <v-col
                cols="12"
                sm="4"
                md="4"
            >
              <v-autocomplete
                  v-model="filterJenis"
                  :items="itemsJenisPotensi"
                  item-value="id"
                  item-text="description"
                  dense
                  small-chips
                  hide-details
                  :label="lang==='id'? 'Jenis Potensi/Kawasan' :'Types of Potentials/Areas'"
                  class="ml-1 mr-1"
                  multiple
                  clearable
              ></v-autocomplete>
            </v-col>
            <v-col
                cols="12"
                sm="4"
                md="4"
            >
              <v-autocomplete
                  v-model="filterFareaBean"
                  :items="itemsFArea"
                  item-value="id"
                  item-text="description"
                  dense
                  small-chips
                  hide-details
                  :label="lang==='id'? 'Kabupaten/Kota' : 'District/City'"
                  class="ml-1 mr-1"
                  multiple
                  clearable
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>

            <v-col cols="6" sm="2" md="2">
              <v-btn color="primary" small @click="fetchFPotensi">
                Terapkan
                <v-icon small class="ml-1" color="green lighten-2">mdi-filter</v-icon>
              </v-btn>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-expand-transition>

    <v-data-table
        v-model="selectedItems"
        :single-select="!multiSelect"
        :show-select="multiSelect"
        :headers="headers"
        :items="fPotensisFiltered"
        :page.sync="currentPage"
        :items-per-page="pageSize"
        hide-default-footer
        class="elevation-0"
        @page-count="totalTablePages =totalPaginationPages "
    >
      <template v-slot:top>
        <v-row align="center" class="ml-4 mr-4">
          <v-switch
              v-model="multiSelect"
              :label="multiSelect?'Multi Select':'Single Select'"
              x-small
              class="pa-3"
          ></v-switch>
          <v-btn
              fab
              dark
              color="red accent-4"
              x-small
              :disabled="!multiSelect"
              class="mr-2"
              @click="deleteDialogMultiShow"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-menu offset-y style="align-items: start">
            <template v-slot:activator="{ on, attrs }" >
              <v-btn
                  class="mx-1"
                  fab
                  dark
                  x-small
                  color="blue"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-view-grid</v-icon>
              </v-btn>

            </template>
            <v-list color="grey lighten-4" class="mr" >
              <v-list-item>
                <v-btn
                    class="mx-1"
                    plain
                    elevation="0"
                    color="gray darken-1"
                    small
                    @click="translateDialogShow"
                    
                >
                  <v-icon color="red">mdi-translate</v-icon>
                  Translate to English
                </v-btn>
              </v-list-item>

            </v-list>

          </v-menu>
          <v-btn
              fab
              dark
              color="green"
              x-small
              @click="showDialogNew"
              :disabled="multiSelect"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:[`item.statusActive`]="{ item }">
        <v-chip
            :color="getColorStatusActive(item.statusActive)"
            dark
            small
        >
          {{ item.statusActive==true?'Aktif': '-' }}
        </v-chip>
      </template>

      <template v-slot:[`item.number`]="{ index }">
        {{ (index + ((currentPage-1)*pageSize) +1 )}}
      </template>

      <template v-slot:[`item.avatar`]="{ item}">
          <v-img
              :lazy-src="lookupImageUrlLazy(item)"
              :src="lookupImageUrl(item)"
              alt="avatar"
              width="70px"
              height="70px"
              class="ma-2 rounded"
          >
          </v-img>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <div class="fill-height">
          <div class="subtitle-1" v-if="lang==='id'">
            {{item.kode2}}
          </div>
          <div class="subtitle-1" v-if="lang==='en'">
            {{item.kode2En }}
          </div>
          <div class="subtitle-2 font-weight-light" v-if="item.kode1">
            *{{item.kode1}}
          </div>
        </div>
      </template>

      <template v-slot:[`item.fpotensiTypeBean`]="{ item }">
        <div class="fill-height">
          <div  class="subtitle-2">
            {{ item.jenis }}
          </div>
          <div  class="text-caption small" v-if="lang==='id'">
            {{ lookupFSectorType(item.fsectorTypeBean).description }}
          </div>
          <div  class="text-caption small" v-if="lang==='en'">
            {{ lookupFSectorType(item.fsectorTypeBean).descriptionEn }}
          </div>
          <div  class="text-caption small" v-if="lang==='id'">
            {{ lookupFKomoditiType(item.fkomoditiTypeBean).description }}
          </div>
          <div  class="text-caption small" v-if="lang==='en'">
            {{ lookupFKomoditiType(item.fkomoditiTypeBean).descriptionEn }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.fdivisionBean`]="{ item }">
        <div class="fill-height">
          <div  class="text-caption small">
            {{ lookupFDivision(item.fdivisionBean).description }}
          </div>
          <div>
            <v-chip
                :color="getColorStatusActive(item.statusActive)"
                dark
                x-small
            >
              {{ item.statusActive==true?'Aktif': '-' }}
            </v-chip>
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            @click="showDialogEdit(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="warning"
          >
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
            @click="deleteDialogShow(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="red accent-4"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>

    <v-container>
      <v-row justify="end" align="center">
        <v-col
        cols="4"
        md="2"
        sm="2"
        >
          <v-select
              v-model="pageSize"
              :items="pageSizes"
              label="Items per page"
          ></v-select>
        </v-col>
        <v-col
            cols="10"
            md="9"
            sm="8"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
              total-visible="8"
              circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <DeleteConfirmDialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
        @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteConfirmDialog>
    <ConfirmDialog
        ref="refConfirmDialog"
        @eventFromOkeConfirmDialog="confirmDialogOke"
    ></ConfirmDialog>
    <FPotensiDialog
          :formMode.sync="formMode"
          :itemsFDivision = "itemsFDivision"
          :itemsFSectorType = "itemsFSectorType"
          :itemsFKomoditiType = "itemsFKomoditiType"
          :itemsJenis = "itemsJenis"
          :itemsFArea="itemsFArea"
          :itemsFSubArea="itemsFSubArea"
          :kawasan="kawasan"

          ref="refFormDialog"
          @eventFromFormDialogNew="saveDataNew"
          @eventFromFormDialogEdit="saveDataEdit"
    ></FPotensiDialog>
    <v-dialog width="100px" v-model="dialogLoading" >
      <v-card class="px-4 py-7">
        <v-row justify="center">
          <v-col cols="12" align="center">
            <v-progress-circular
              :size="40"
              :width="5"
              v-if="loading"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>
import FPotensiService from '../../../services/apiservices/f-potensi-service';
import FDivisionService from "../../../services/apiservices/f-division-service";
import FSectorTypeService from "../../../services/apiservices/f-sector-type-service";
import FKomoditiTypeService from "../../../services/apiservices/f-komoditi-type-service";

import DeleteConfirmDialog from "../../utils/DeleteConfirmDialog";

import FPotensiDialog from "./FPotensiDialog";
import FormMode from "../../../models/form-mode";
import FPotensi from '../../../models/f-potensi'
import FileService from "../../../services/apiservices/file-service";
import FSubAreaService from "@/services/apiservices/f-sub-area-service";
import FAreaService from "@/services/apiservices/f-area-service";
import EPotensiType from "@/models/e-potensi-type";
import FPotensiFilter from "@/models/payload/f-potensi-filter";
import ConfirmDialog from "@/components/utils/ConfirmDialog.vue";
import LangTranslatorService from "@/services/apiservices/lang-translator-service";

export default {
  props:{
    kawasan: Boolean
  },
  components: { FPotensiDialog, DeleteConfirmDialog, ConfirmDialog},
  data () {
    return {
      showFilter: false,

      dialogLoading: false,
      loading: false,

      title: 'POTENSI',
      snackbar: false,
      snackBarMesage: '',

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [5, 10, 25, 50, 150, 500],

      search: '',
      filterJenis: '',
      filterFareaBean: '',
      filterFsectorTypeBean: '',
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'avatar',
        },
        { text: 'Deskripsi', value: 'description', width:"30%" },
        { text: 'Jenis', value: 'fpotensiTypeBean' },
        { text: 'Div (Kab/Kota)', value: 'fdivisionBean' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      fPotensis:[
        new FPotensi(0, '','')
      ],
      itemsFDivision: [],
      itemsFSectorType:[],
      itemsFKomoditiType: [],
      itemsJenis: ['POTENSI', 'PELUANG', 'IPRO'],
      itemsFArea:[],
      itemsFSubArea:[],


    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFPotensi()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage==1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)
          this.fetchFPotensi()
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      }
    },
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFSectorType()
        }
      }
    },
  },
  computed: {
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
    currentUser(){
      return this.$store.state.auth.user;
    },
    fPotensisFiltered(){
      return this.fPotensis
    },
    titleComputed(){
      if (this.kawasan){
        return "KAWASAN"
      }else {
        return "POTENSI/IPRO"
      }
    },

    itemsJenisPotensi(){
      let listJenis = [EPotensiType.IPRO, EPotensiType.POTENSI, EPotensiType.PELUANG]
      if (this.kawasan){
        listJenis = [EPotensiType.KPI, EPotensiType.KIH, EPotensiType.KI, EPotensiType.KEK]
      }
      return listJenis
    }

  },
  methods: {
    searchOnEnter(event){
      if (this.search !== event.target.value) { //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1
        this.search = event.target.value
        this.fetchFPotensi()

      }
    },

    fetchParent(){
      if (this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
            response=>{
              this.itemsFDivision = [response.data]
            },
            error=>{
              console.log(error.response)
            }
        )
      }else {
        FDivisionService.getAllFDivision().then(
            response => {
              // console.log(JSON.stringify(response.data))
              this.itemsFDivision = response.data
            },
            error => {
              console.log(error.response)
            }
        )

      }

      FSectorTypeService.getAllFSectorType().then(
          response => {
            // console.log(JSON.stringify(response.data))
            this.itemsFSectorType = response.data
          },
          error => {
            console.log(error.response)
          }
      )
      FKomoditiTypeService.getAllFKomoditiType().then(
          response => {
            // console.log(JSON.stringify(response.data))
            this.itemsFKomoditiType = response.data
          },
          error => {
            console.log(error.response)
          }
      )

      FSubAreaService.getAllFSubArea().then(
          response => {
            this.itemsFSubArea = response.data
          },
          error => {
            console.log(error)
          }
      )
      FAreaService.getAllFArea().then(
          response => {
            // console.log(JSON.stringify(response.data))
            this.itemsFArea = response.data
          },
          error => {
            console.log(error.response)
          }
      )


    },
    fetchFPotensi() {

      // console.log(this.kawasan)

      const fpotensiFilter = new FPotensiFilter()
      fpotensiFilter.pageNo = this.currentPage
      fpotensiFilter.pageSize = this.pageSize
      fpotensiFilter.search = this.search

      fpotensiFilter.listJenis = this.filterJenis
      fpotensiFilter.jenis = this.jenis

      fpotensiFilter.fareaIds = this.filterFareaBean

      if (fpotensiFilter.listJenis.length ==0) fpotensiFilter.listJenis = []
      if (fpotensiFilter.fareaIds.length ==0) fpotensiFilter.fareaIds = []


      if(this.kawasan) {
        // FPotensiService.getAllFPotensiContainingKawasan(this.currentPage, this.pageSize, "id", "DESC", this.search).then(
        //     response => {
        //       // console.log(response.data.items)
        //       const {items, totalPages} = response.data
        //       this.fPotensis = items
        //       this.totalPaginationPages = totalPages
        //       console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
        //     },
        //     error => {
        //       console.log(error.response)
        //     }
        // )

        FPotensiService.getAllFPotensiContainingKawasanExt(fpotensiFilter).then(
            response => {
              console.log(response.data.items)
              const { items, totalPages} = response.data
              this.fPotensis = items
              this.totalPaginationPages = totalPages
            },
            error => {
              console.log(error.response)
            }
        )
      }else {
        // FPotensiService.getAllFPotensiContainingPotensi(this.currentPage, this.pageSize, "id", "DESC", this.search).then(
        //     response => {
        //       // console.log(response.data.items)
        //       const {items, totalPages} = response.data
        //       this.fPotensis = items
        //       this.totalPaginationPages = totalPages
        //       console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
        //     },
        //     error => {
        //       console.log(error.response)
        //     }
        // )

        FPotensiService.getAllFPotensiContainingPotensiExt(fpotensiFilter).then(
            response => {
              console.log(response.data.items)
              const { items, totalPages} = response.data
              this.fPotensis = items
              this.totalPaginationPages = totalPages
            },
            error => {
              console.log(error.response)
            }
        )

      }

    },
    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },
    translateDialogShow(){
      this.$refs.refConfirmDialog.showDialog("Yakin akan Membuat Terjemahan  Data Dalam Daftar? ", "(Yang belum mempunyai terjemahan)")
    },
    //DUAL BAHASA
    confirmDialogOke(){
      this.snackBarMesage = ''
      this.dialogLoading = true
      this.loading = true
      let itemsFPotensiTranslate = []
      let itemsFPotensiSorted = []
      this.fPotensis.sort((a, b) => a.id > b.id ? 1 : -1).forEach((item) => {
        let itemFPotensiTranslate = []
        let emptyValue = "-";
        if ((item.kode2 !== null && item.kode2 !== undefined && item.kode2 !== "" && item.kode2 !== "-") && (item.kode2En === null || item.kode2En === undefined ||item.kode2En === "" || item.kode2En === "-")) {
          itemFPotensiTranslate.push(item.kode2)
        } else {
          itemFPotensiTranslate.push(emptyValue);
        }
        if ((item.description !== null && item.description !== undefined && item.description !== "" && item.description !== "-") && (item.descriptionEn === null || item.descriptionEn === undefined ||item.descriptionEn === "" || item.descriptionEn === "-")) {
          itemFPotensiTranslate.push(item.description)
        } else {
          itemFPotensiTranslate.push(emptyValue);
        }
        if ((item.bidangUsaha !== null && item.bidangUsaha !== undefined && item.bidangUsaha !== "" && item.bidangUsaha !== "-") && (item.bidangUsahaEn === null || item.bidangUsahaEn === undefined ||item.bidangUsahaEn === "" || item.bidangUsahaEn === "-")) {
          itemFPotensiTranslate.push(item.bidangUsaha)
        } else {
          itemFPotensiTranslate.push(emptyValue);
        }
        itemsFPotensiTranslate.push(itemFPotensiTranslate)
        itemsFPotensiSorted.push(item)
      })
      console.log(itemsFPotensiTranslate)
      // console.log(JSON.stringify(itemsFPotensiSorted))
      LangTranslatorService.getPostChatGptArrayTranslateIdToEn(encodeURIComponent(JSON.stringify(itemsFPotensiTranslate))).then(
        (response) => {
          try{
            let itemsFPotensiTranslateResponse = response.data.translation.replaceAll("+", " ").replaceAll("=", " ").trim()
            let itemsFPotensiTranslateResponseParsed = JSON.parse(itemsFPotensiTranslateResponse)
            console.log(itemsFPotensiTranslateResponse)
            console.log(itemsFPotensiTranslateResponseParsed)
              itemsFPotensiSorted.forEach((itemFPotensiSorted,index)=> {
                if ((itemFPotensiSorted.kode2En === null ||
                itemFPotensiSorted.kode2En === undefined ||
                itemFPotensiSorted.kode2En === "" ||
                itemFPotensiSorted.kode2En === "-") && itemsFPotensiTranslateResponseParsed[index][0] !== "-") {
                  itemFPotensiSorted.kode2En =
                  itemsFPotensiTranslateResponseParsed[index][0]
                }
                if ((itemFPotensiSorted.descriptionEn === null ||
                itemFPotensiSorted.descriptionEn === undefined ||
                itemFPotensiSorted.descriptionEn === "" ||
                itemFPotensiSorted.descriptionEn === "-") && itemsFPotensiTranslateResponseParsed[index][1] !== "-") {
                  itemFPotensiSorted.descriptionEn =
                  itemsFPotensiTranslateResponseParsed[index][1]
                }
                if ((itemFPotensiSorted.bidangUsahaEn === null ||
                itemFPotensiSorted.bidangUsahaEn === undefined ||
                itemFPotensiSorted.bidangUsahaEn === "" ||
                itemFPotensiSorted.bidangUsahaEn === "-") && itemsFPotensiTranslateResponseParsed[index][2] !== "-") {
                  itemFPotensiSorted.bidangUsahaEn = itemsFPotensiTranslateResponseParsed[index][2];
                }
                console.log(itemFPotensiSorted)
                FPotensiService.updateFPotensi(itemFPotensiSorted).then(
                  ()=>{
                    console.log('Update Oke')
                  }
                  )
                })
              this.dialogLoading = false;
              this.loading = false
              this.snackbar = true
              if(this.lang==='id'){
                this.snackBarMesage = 'Data berhasil diterjemahkan'
              }
              if(this.lang==='en'){
                this.snackBarMesage = 'Data successfully translated'
              }
              this.$refs.refConfirmDialog.setDialogState(false)
          }
          catch(error){
            this.dialogLoading = false;
            this.loading = false
            this.snackbar = true
            if(this.lang==='id'){
              this.snackBarMesage = 'Data yang diterjemahkan melebihi batas'
            }
            if(this.lang==='en'){
              this.snackBarMesage = 'The translated data exceeds the limit'
            }
            this.$refs.refConfirmDialog.setDialogState(false)
            console.log(error)
          }
          
          
        },
        (error) => {
          // console.log(" Hasil Error: " + item.description)
           this.snackBarMesage = 'Data yang diterjemahkan melebihi batas'
          this.snackbar = true
          console.log(error.data);
        }
      );
    },

    showDialogNew() {

      this.itemSelectedIndex =-1
      const itemModified = Object.assign({}, '')
      // this.formDialogShow = true
      this.formMode = FormMode.NEW_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified)
    },
    saveDataNew(itemFromRest){
      this.itemSelected = itemFromRest
      this.closeDialog()
      /**
       * Setiap data baru harus terlihat
       */
      // this.fPotensis.push(this.itemSelected)
      // this.fPotensis = [itemFromRest].concat(this.fPotensis)
      // this.fPotensis.unshift(itemFromRest)
      this.fetchFPotensi()
    },

    showDialogEdit (item) {
      this.itemSelectedIndex = this.fPotensisFiltered.indexOf(item)
      const itemModified = Object.assign({}, item)
      // this.formDialogShow = true
      this.formMode = FormMode.EDIT_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified)
    },
    saveDataEdit(item){
        this.itemSelected = item
        try {
          Object.assign(this.fPotensis[this.itemSelectedIndex], this.itemSelected)
        }catch (e) {
            this.fetchFPotensi()
            e.toString()
        }
        this.closeDialog()
    },

    deleteDialogShow (item) {
      // console.log(item)
      this.itemSelectedIndex = this.fPotensisFiltered.indexOf(item)
      this.itemSelected = Object.assign({}, item)
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, item.username)
    },
    deleteDialogMultiShow(){
      if (this.multiSelect===true){
        // console.log(`nilai ${this.selectedItems}`)
        this.$refs.refDeleteConfirmDialog.showDialogMulti(this.selectedItems, `${this.selectedItems.length} items selected`)
      }
    },
    deleteItemConfirmedSingleSelect(index){
      console.log("delete SingleItem: " + index)

      const deletedItem = this.fPotensis[this.itemSelectedIndex]
      FPotensiService.deleteFPotensi(deletedItem.id).then(
        () => {
          // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
          this.fPotensis.splice(this.itemSelectedIndex, 1)
          this.closeDialog()
        },
        error => {
          console.log(error)

          // this.snackBarMesage = 'gagal hapus (digunakan oleh data anakk)'
          // this.snackbar = true
          // this.$refs.refDeleteConfirmDialog.setDialogState(false)

        }
      )
    },
    deleteItemConfirmedMultiSelect(items){
      // console.log(okeMessage)
      if (items.length >-1){
        let itemIds = []
        for (let i=0; i<items.length; i++){
            itemIds.push(items[i].id)
        }
        // console.log("Item idex: " + itemIds)

        FPotensiService.deleteAllFPotensi(itemIds).then(
            response => {
              if (response.data) {
                  for (let i = 0; i < items.length; i++) {
                    if (! response.data.includes(items[i].id)  || response.data.length === 0) {
                      const index = this.fPotensisFiltered.indexOf(items[i])
                      this.fPotensis.splice(index, 1)
                    }
                    if (response.data.length > 0) {
                      this.snackBarMesage = `${response.data.length} items gagal hapus (digunakan oleh data anak)`
                      this.snackbar = true
                    }

                    console.log("Undeleted Items: " + response.data + " = " + items[i].id)
                }
                this.closeDialog()
              }
            },
            error => {
              console.log("error " + error)
            }
        )

        // console.log(items)
        // FPotensiService.deleteAllFPotensis()
      }

    },
    closeDialog () {
      // this.myConfirmDialog = false
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FPotensi())
        this.itemSelectedIndex = -1
      })
    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFSectorType (fsectorTypeBean) {
      const str = this.itemsFSectorType.filter(x => x.id===fsectorTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFKomoditiType (fkomoditiTypeBean) {
      const str = this.itemsFKomoditiType.filter(x => x.id===fkomoditiTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

  },

  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }else {
      this.fetchParent()
      this.fetchFPotensi()

    }
  }

}
</script>

<style scoped>

</style>