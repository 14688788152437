<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :hide-overlay="$vuetify.breakpoint.smAndDown"
    >

      <v-card>
        <v-form  v-model="valid" ref="form">

          <v-toolbar
              dark
              color="brown"
              class="color-gradient-1"
              dense
          >
            <v-btn
                icon
                dark
                @click="closeForm"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>USERS</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM' ">EDIT </span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM' "> BARU</span>

            <span class="ml-2 mr-2 font-weight-medium grey--text" v-show="isItemModified"> | </span>
            <v-chip
                class="ma-2"
                color="warning"
                outlined
                x-small
                v-show="isItemModified"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              modfied
            </v-chip>

            <v-spacer></v-spacer>
            <v-toolbar-items >
              <v-btn
                  dark
                  text
                  @click="save"
                  :disabled="!valid || isItemModified===false"
                  class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <v-text-field
                      v-model="itemModified.username"
                      :rules="rulesLenght"
                      prepend-inner-icon="mdi-account"
                      label="User Name"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="8"
                >
                  <v-text-field
                      prepend-inner-icon="mdi-email"
                      v-model="itemModified.email"
                      :rules="rulesLenght"
                      label="Email"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    cols="12"
                    sm="8"
                    md="8"
                >
                  <v-text-field
                      v-model="itemModified.password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      label="Password"
                      hide-details="auto"
                      @click:append="show1 = !show1"
                      clearable
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="2"
                    md="2"
                >
                  <v-switch
                      v-model="itemModified.isAccountNonLocked"
                      :label="itemModified.isAccountNonLocked?'Aktif':'Locked'"
                      x-small
                      class="pa-3"
                  ></v-switch>
                </v-col>

              </v-row>


              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                  <v-text-field
                      prepend-inner-icon="mdi-phone"
                      v-model="itemModified.phone"
                      label="Phone"
                      prefix="+62 "
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="8"
                >
                  <v-text-field
                      v-model="itemModified.fullName"
                      label="Nama Lengkap"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-autocomplete
                      v-model="itemModified.fdivisionBean"
                      :items="itemsFDivision"
                      :rules="rulesNotEmtpy"
                      item-value="id"
                      item-text="description"
                      auto-select-first
                      dense
                      chips
                      deletable-chips
                      color="blue-grey lighten-2"
                      label="Divisi/Pemilik Data"
                      hint="Divisi/Pemilik Data"
                      persistent-hint
                  ></v-autocomplete>
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-autocomplete
                      v-model="itemModified.organizationLevel"
                      :items="itemsOrganizationLevel"
                      item-value="id"
                      item-text="description"
                      auto-select-first
                      dense
                      chips
                      deletable-chips
                      color="blue-grey lighten-2"
                      label="Level Organisasi"
                      hint="SYS=Holding, CORP=Company, DIV=Pemilik Data"
                      persistent-hint
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="12"
                    sm="10"
                    md="10"
                >
                  <v-autocomplete
                      v-model="itemModified.roles"
                      :items="itemsRolesComputed"
                      auto-select-first
                      dense
                      chips
                      deletable-chips
                      color="blue-grey lighten-2"
                      label="Roles"
                      hint="Bisa dipilih multi"
                      persistent-hint
                      multiple
                  ></v-autocomplete>

                </v-col>
              </v-row>

              <v-row v-if="false">
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-autocomplete
                      v-model="itemModified.salesmanOf"
                      :items="itemsFSalesman"
                      item-value="id"
                      item-text="spname"
                      auto-select-first
                      dense
                      chips
                      deletable-chips
                      color="blue-grey lighten-2"
                      label="Agen1/Agen2/CS"
                      hint="Specific User = Agen1/Agen2/CS"
                      persistent-hint
                  >
                    <template v-slot:selection="data">
                      <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="removeAutoCompleteSalesmanOf(data.item)"
                      >
                        <v-avatar left>
                          <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                        </v-avatar>
                        {{ data.item.spname }}
                        <span class="font-weight-light caption"> *{{ data.item.spcode }} </span>
                      </v-chip>
                    </template>

                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-avatar>
                          <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
<!--?                          <v-list-item-title v-html="data.item.spname"></v-list-item-title>-->
                          <v-list-item-title>{{data.item.spname}} *{{data.item.spcode}}</v-list-item-title>
                          <v-list-item-subtitle >
                            {{lookupFDivision(data.item.fdivisionBean)}}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>

                  </v-autocomplete>

                </v-col>

              </v-row>

            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-chip
                class="ml-4"
                color="error"
                outlined
                close
                small
                v-show="formDialogOptions.errorMessage"
            >
              {{formDialogOptions.errorMessage}}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="closeForm"
                class="hidden-sm-and-down"
            >
              Batal
            </v-btn>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="save"
                :disabled="!valid || isItemModified===false"
                class="hidden-sm-and-down"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>

      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>

    </v-dialog>
  </div>

</template>

<script>
import AuthService from '../../services/auth-service'
import UserService from '../../services/user-service';
import FileService from "../../services/apiservices/file-service";

import CloseConfirmDialog from "../../components/utils/CloseConfirmDialog";
import User from '../../models/user'
import FormMode from "../../models/form-mode";
import FPegawai from "../../models/f-pegawai";
import ERole from "../../models/e-role";
import FDivision from "../../models/f-division";
import {EOrganizationLevels} from "../../models/e-organization-level";

export default {
  name: "UsersDialog",
  components: { CloseConfirmDialog },
  props:{
    formMode: String
  },
  data() {
    return {
      show1: false,
      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },

      selectedItemIndex: -1,
      itemDefault: '',
      itemModified: new User(),

      selectFDivision: {id: 0, kode1: '', description: '' },
      itemsFDivision: [
        new FDivision(0, '','')
      ],
      itemsFSalesman:[
        new FPegawai(0, '','')
      ],
      // ERole.ROLE_ADMIN,
      itemsRoles:[
        ERole.ROLE_ADMIN1,
        ERole.ROLE_ADMIN2,
        ERole.ROLE_USER,
        ERole.ROLE_USER1,

        ERole.ROLE_ACCOUNT,
        ERole.ROLE_FINANCE,

        // ERole.ROLE_MANAGER,
        // ERole.ROLE_MANAGER_1,
        // ERole.ROLE_MANAGER_2,

        ERole.ROLE_SALES,
        // ERole.ROLE_SALES_1,
        // ERole.ROLE_SALES_2,

        ERole.ROLE_PIUTANG,
        ERole.ROLE_PIUTANG_1,

        ERole.ROLE_HUTANG,
        ERole.ROLE_HUTANG_1,

        ERole.ROLE_PURCHASE,
        ERole.ROLE_PURCHASE_1,
        ERole.ROLE_PURCHASE_2,

        ERole.ROLE_INVENTORY,
        ERole.ROLE_INVENTORY_1,
        ERole.ROLE_INVENTORY_2,

        ERole.ROLE_AGEN,
        ERole.ROLE_RSL_1,
        ERole.ROLE_RSL_2,

        ERole.ROLE_CS,

      ],
      // itemsOrganizationLevel:[
      //   'SYS',
      //   'CORP',
      //   'DIV'
      // ],
      itemsOrganizationLevel: EOrganizationLevels,

      valid: false,
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 3 || 'Harus lebih besar dari 3 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],

    }
  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    itemsRolesComputed(){
      let myRoles = this.itemsRoles
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_ADMIN")  ){
            myRoles.push(ERole.ROLE_ADMIN)
        }
      }

      return myRoles
    },
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault)
      const modifiedItem = JSON.stringify(this.itemModified)

      // console.log(`${defaultItem}`)
      // console.log(`${modifiedItem}`)

      return defaultItem !== modifiedItem
    },
    itemsOrganizationLevelComputed(){
      let newItemsLevelOrganisasi = []
      if (this.currentUser.organizationLevel ==="DIV"){
        newItemsLevelOrganisasi.push(this.currentUser.organizationLevel)
      }else {
        newItemsLevelOrganisasi = this.itemsOrganizationLevel
      }
      return newItemsLevelOrganisasi
    }

  },
  watch: {
  },
  methods: {
    showDialog(selectedIndex, item, itemsFDivision, itemsFSalesman) {
      // console.log("show UserDialog " + item.id)
      // console.log(this.formMode)
      this.dialogShow = !this.dialogShow
      if (selectedIndex >-1) {
        this.selectedIndex = selectedIndex
        this.initializeEditMode(item)
      }else {
        this.itemDefault = new User(0, '', '', '')
        this.itemModified = new User(0,'', '', '')
        this.selectedIndex = -1
      }
      this.itemsFDivision = itemsFDivision
      this.itemsFSalesman = itemsFSalesman
    },
    setDialogState(value){
      this.dialogShow =value
    },

    save(){
      if (this.isItemModified===false){
        //Close aja
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
        return
      }
      if (this.$refs.form.validate()){
        if (this.formMode===FormMode.EDIT_FORM) {
          //di proses di server -> Jika kosong maka tidak akan dirubah di server
          // this.itemModified.password = ''

          // console.log(JSON.stringify(this.itemModified))

          AuthService.updateUser(this.itemModified).then(
              () => {
                // console.log(response.data)
                this.$emit('eventFromFormDialogEdit', this.itemModified)
              },
              error => {
                // console.log(error);
                this.formDialogOptions.errorMessage = error.response.data
              }
          )
        }else {
          AuthService.register(this.itemModified).then(
              response =>{
                this.itemModified.password = 'Welcome1'
                this.$emit('eventFromFormDialogNew', response.data)
              },
              error => {
                this.formDialogOptions.errorMessage = error.response.data.message
              }
          )
        }

      }
    },
    closeForm(){
      if (! this.isItemModified){
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
      }else {
        this.$refs.refCloseConfirmDialog.showDialog(" Sudah terdapat modifikasi data", "Tetap tutup dan reset perubahan?")
      }
    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },
    initializeEditMode(item){
      // this.itemDefault = item
      // this.itemModified = item

      this.formDialogOptions.errorMessage = ''
      const id = item.id
      UserService.getUserById(id).then(
          response =>{
            // console.log(response.data)
            this.itemDefault = Object.assign({}, response.data)
            this.itemModified = response.data
            /**
             * Harus di rubah jadi aarray dahulu
             */
            this.itemDefault.roles = this.rolesToArray(this.itemDefault.roles)
            this.itemModified.roles = this.rolesToArray(this.itemModified.roles)
          },
          error =>{
            console.log(error)
          }
      )
    },
    removeAutoCompleteSalesmanOf (item) {
      this.itemModified.salesmanOf = null
      console.log(item)
      // const index = this.itemsFPegawai.indexOf(item.name)
      // if (index >= 0) this.itemsFPegawai.splice(index, 1)
    },
    lookupFSalesman (fsalesmanBean) {
      const str = this.itemsFSalesman.filter(x => x.id==fsalesmanBean)
      if (str.length>0){
        return `${str[0].spcode} - ${str[0].spname} `
      }else {
        return '-'
      }

    },
    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },

    rolesToArray(roles){
      const arrRoles = [];
      roles.forEach(
          role=>{
            arrRoles.push(role.name);
          }
      )
      return arrRoles
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/account_icon.png'
      }else {
        return FileService.image_url_medium(item.avatarImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/account_icon.png'
    },


  }

}
</script>

<style scoped>

</style>