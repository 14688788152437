import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/public/HomeView')
  },
  {
    path: '/home',
    name: 'homeView',
    component: () => import('../views/public/HomeView')
  },

  {
    path: '/dashboard-utama',
    name: 'DashboardUtama',
    component: () => import('../views/Dashboard')
  },
  {
    path: '/ini-jatim',
    name: 'IniJatim',
    component: () => import('../views/public/IniJatimView')
  },

  {
    path: '/login',
    name: 'Login',
    component: () =>
        import ('../views/Login.vue')
  },
  {
    path: '/change-profile',
    name: 'ChangeProfile',
    component: () =>
        import ('../views/admin/ChangeProfile'),
  },
  {
    path: '/admin-sistem',
    name: 'AdminSistem',
    component: () =>
        import ('../views/admin/SettingAndUser.vue')
  },
  {
    path: '/admin-struktur-organisasi',
    name: 'StrukturOrganisasi',
    component: () =>
        import ('../views/admin/StrukturOrganisasi.vue')
  },
  {
    path: "/admin-setting-one",
    name: "AdminSettingOne",
    component: () => import("../views/admin/SettingOneView.vue"),
  },

  {
    path: '/admin-din-carousel',
    name: 'DinCarouselView',
    component: () => import('../views/admin-setup/DinCarouselView.vue')
  },

  {
    path: '/admin-jenis-infrastruktur',
    name: 'AdminJenisInfrastruktur',
    component: () => import('../views/admin_infra/InfrastructureTypeView')
  },
  {
    path: '/admin-infrastruktur',
    name: 'AdminInfrastructure',
    component: () => import('../views/admin_infra/InfrastructureView')
  },
  {
    path: '/admin-jenis-dayadukung',
    name: 'AdminJenisDayaDukung',
    component: () => import('../views/admin_dayadukung/DayaDukungTypeView')
  },
  {
    path: '/admin-dayadukung',
    name: 'AdminDayaDukung',
    component: () => import('../views/admin_dayadukung/DayaDukungView')
  },
  {
    path: '/admin-sector-type',
    name: 'AdminSectorType',
    component: () => import('../views/admin_other/SectorType')
  },

  {
    path: '/admin-komoditi',
    name: 'AdminKomoditiView',
    component: () => import('../views/admin_komoditi/KomoditiView')
  },
  {
    path: '/admin-komoditi-type',
    name: 'AdminKomoditiType',
    component: () => import('../views/admin_komoditi/KomoditiTypeView')
  },
  {
    path: '/admin-potensi',
    name: 'AdminPotensi',
    component: () => import('../views/admin_potensi/PotensiView')
  },
  {
    path: '/admin-kawasan',
    name: 'AdminKawasan',
    component: () => import('../views/admin_potensi/KawasanView')
  },

  {
    path: '/admin-wilayah',
    name: 'RegionView',
    component: () => import('../views/wilayah/RegionView')
  },
  {
    path: '/chart-visitor',
    name: 'ChartVisitorView',
    component: () =>
        import ('../views/public/ChartVisitorView')
},



  {
    path: '/public-potensi-detail/:id',
    name: 'PotensiDetailView',
    component: () =>
        import ('../views/public/PotensiDetailView')
  },
  {
    path: '/public-kawasan-detail/:id',
    name: 'PotensiDetailView',
    component: () =>
        import ('../views/public/KawasanDetailView')
  },

  {
    path: '/public-peluang-investasi',
    name: 'PublicPeluangInvestasi',
    component: () => import('../views/public/PotensiPeluangView')
  },

  {
    path: '/public-ipro',
    name: 'PublicPotensi',
    component: () => import('../views/public/PotensiPeluangView')
  },
  {
    path: '/public-peluang',
    name: 'PublicPotensi',
    component: () => import('../views/public/PeluangView')
  },
  {
    path: '/public-potensi',
    name: 'PublicPotensi',
    component: () => import('../views/public/PotensiView')
  },

  {
    path: '/public-komoditi',
    name: 'PublicKomoditi',
    component: () => import('../views/public/KomoditiView')
  },
  {
    path: '/public-dayadukung',
    name: 'PublicDayaDukung',
    component: () => import('../views/public/DayaDukungView')
  },
  {
    path: '/public-profil-daerah',
    name: 'PublicProfilDaerahView',
    component: () => import('../views/public/ProfilDaerahView')
  },
  {
    path: '/public-profil-daerah-detil/:id',
    name: 'PublicProfilDaerahDetilView',
    component: () => import('../views/public/ProfilDaerahDetilView')
  },

  {
    path: '/public-infrastruktur',
    name: 'PublicInfrastructur',
    component: () => import('../views/public/InfrastrukturView')
  },
  {
    path: '/public-insentif',
    name: 'PublicInsentif',
    component: () => import('../views/public/InsentifView')
  },



  {
    path: '/public-ki',
    name: 'PublicKi',
    component: () => import('../views/public/KiView')
  },
  {
    path: '/public-kih',
    name: 'PublicKih',
    component: () => import('../views/public/KihView')
  },
  {
    path: '/public-kek',
    name: 'PublicKek',
    component: () => import('../views/public/KekView')
  },
  {
    path: '/public-kpi',
    name: 'PublicKpi',
    component: () => import('../views/public/KpiView')
  },

  {
    path: '/public-ebt',
    name: 'PublicEbtView',
    component: () => import('../views/public/EbtView')
  },

  {
    path: '/setup-satuan',
    name: 'SetupSatuan',
    // lazy-loaded
    component: () => import('../components/setup/satuan/FSatuanTable')
  },

  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
