<template>
  <nav>
    <v-toolbar elevation="0" class="blue darken-3 color-gradient-1 elevation-0">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        class="grey--text hidden-md-and-up"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="grey--text mr-2" v-if="false">
        <v-flex class="d-flex">
          <a
            href="https://inijatim.jatimprov.go.id/"
            style="text-decoration: none"
            class="justify-end"
          >
            <v-flex column class="x-small-line-height text-center ml-n4">
              <div>
                <v-img
                  src="../assets/images/inijatim_logo.png"
                  width="90"
                  height="40"
                >
                </v-img>
              </div>
            </v-flex>
          </a>
        </v-flex>
      </v-toolbar-title>
      <v-toolbar-items
        v-if="$route.path.startsWith('/public-potensi-detail/')"
        class="mr-4 hidden-sm-and-down"
      >
        <v-btn icon dark small route to="/public-potensi">
          <v-icon medium>mdi-arrow-left</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items
        v-if="$route.path.startsWith('/public-profil-daerah-detil/')"
        class="mr-4 hidden-sm-and-down"
      >
        <v-btn icon dark small route to="/public-profil-daerah">
          <v-icon medium>mdi-arrow-left</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items
        v-if="$route.path.startsWith('/public-kawasan-detail/')"
        class="mr-4 hidden-sm-and-down"
      >
        <v-btn icon dark small route to="/public-ki">
          <v-icon medium>mdi-arrow-left</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-title class="text-uppercase grey--text ml-1">
        <v-flex class="d-flex" v-if="false">
          <v-btn outlined class="rounded-lg" color="white">
            <span class="font-weight-bold red--text text--darken-1 headline"
              >POINT</span
            >
            <span class="font-weight-bold white--text headline">JATIM</span>
          </v-btn>
          <v-flex class="ml-1 align-baseline hidden-sm-and-down" v-if="false">
            <div
              class="small-line-height white--text"
              style="font-size: x-small; font-weight: bold"
            >
              POTENSI
            </div>
            <div
              class="small-line-height white--text"
              style="font-size: x-small; font-weight: bold"
            >
              INVESTASI
            </div>
            <div
              class="small-line-height white--text"
              style="font-size: x-small; font-weight: bold"
            >
              REGIONAL
            </div>
          </v-flex>
        </v-flex>
      </v-toolbar-title>

      <v-toolbar-title class="mt-n1 hidden-sm-and-down d-flex">
        <router-link to="/" class="d-flex">
          <v-flex class="align-center mt-1 text-center">
            <v-flex color="white" class="x1-small-line-height">
              <span
                class="green--text text--lighten-1"
                style="
                  font-family: 'Arial Black';
                  font-weight: bolder;
                  font-size: 30px;
                "
                >POINT</span
              >
              <span
                class="orange--text text--darken-4"
                style="
                  font-family: 'Arial Black';
                  font-weight: bolder;
                  font-size: 30px;
                "
                >JATIM</span
              >
            </v-flex>
            <v-flex class="ml-1 align-baseline hidden-sm-and-down">
              <div class="x2-small-line-height white--text my-font-potential">
                POTENTIAL OPPORTUNITY AND INVESTMENT
              </div>
            </v-flex>
          </v-flex>

          <v-flex class="ml-1">
            <v-img
              lazy-src="../assets/images/logo_point_jatim.png"
              max-height="45"
              max-width="55"
              src="../assets/images/logo_point_jatim.png"
            ></v-img>
          </v-flex>
        </router-link>
      </v-toolbar-title>

      <v-toolbar-title class="ml-4 hidden-sm-and-down row--dense" v-if="false">
        <v-flex v-if="true">
          <v-img
            lazy-src="../assets/images/cetar_logo.png"
            max-height="142"
            max-width="142"
            src="../assets/images/cetar_logo.png"
          ></v-img>
        </v-flex>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-sm-and-down row--dense">
        <v-btn
          small
          plain
          elevation="0"
          color="white darken-1"
          route
          to="/public-ipro"
          v-if="currentUser === null"
        >
          <v-icon class="grey--text text--lighten-2" small>mdi-diamond</v-icon>
          <span class="font-weight-bold caption">IPRO</span>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="hidden-sm-and-down row--dense">
        <v-btn
          small
          plain
          elevation="0"
          color="white"
          route
          to="/public-peluang"
          v-if="currentUser === null"
        >
          <span class="font-weight-bold caption">{{
            $t("peluangInvestasi")
          }}</span>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="hidden-sm-and-down row--dense">
        <v-btn
          small
          plain
          elevation="0"
          color="white darken-1"
          route
          to="/public-potensi"
          v-if="currentUser === null"
        >
          <span class="font-weight-bold caption">{{ $t("potensi") }}</span>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="hidden-sm-and-down row--dense">
        <v-btn
          small
          plain
          elevation="0"
          color="white darken-1"
          route
          to="/public-komoditi"
          v-if="currentUser === null"
        >
          <span class="font-weight-bold caption">{{ $t("komoditas") }}</span>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="hidden-sm-and-down row--dense">
        <v-btn
          small
          plain
          elevation="0"
          color="white darken-1"
          route
          to="/public-profil-daerah"
          v-if="currentUser === null"
        >
          <span class="font-weight-bold caption">{{ $t("profilDaerah") }}</span>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="hidden-sm-and-down row--dense">
        <v-btn
          small
          plain
          elevation="0"
          color="white darken-1"
          route
          to="/public-infrastruktur"
          v-if="currentUser === null"
        >
          <span class="font-weight-bold caption">{{
            $t("infrastruktur")
          }}</span>
        </v-btn>
      </v-toolbar-items>

      <v-toolbar-items class="hidden-sm-and-down row--dense" v-if="false">
        <v-btn
            small
            plain
            elevation="0"
            color="white darken-1"
            route
            to="/public-ebt"
            v-if="currentUser === null"
        >
          <span class="font-weight-bold caption">Energy</span>
        </v-btn>
      </v-toolbar-items>

      <v-toolbar-items class="hidden-sm-and-down row--dense">
        <v-menu
          v-model="menuInfrastuktur"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              plain
              elevation="0"
              v-bind="attrs"
              v-on="on"
              color="white darken-1 "
              class="font-weight-bold caption"
            >
              {{ $t("kawasan") }}
            </v-btn>
          </template>

          <v-card>
            <v-list>
              <v-list-item>
                <v-icon color="orange">mdi-factory</v-icon>
                <v-list-item-action>
                  <v-list-item-title>
                    <v-btn
                      small
                      plain
                      elevation="0"
                      route
                      to="/public-ki"
                      @click="menuInfrastuktur = false"
                      v-if="currentUser === null"
                    >
                      <span>{{ $t("kawasanIndustri") }}</span>
                    </v-btn>
                  </v-list-item-title>
                </v-list-item-action>
              </v-list-item>

              <v-list-item>
                <v-icon color="orange">mdi-factory</v-icon>
                <v-list-item-action>
                  <v-list-item-title>
                    <v-btn
                      small
                      plain
                      elevation="0"
                      route
                      to="/public-kih"
                      @click="menuInfrastuktur = false"
                      v-if="currentUser === null"
                    >
                      <span>{{ $t("kawasanIndustriHalal") }}</span>
                    </v-btn>
                  </v-list-item-title>
                </v-list-item-action>
              </v-list-item>

              <v-list-item>
                <v-icon color="indigo">mdi-star</v-icon>
                <v-list-item-action>
                  <v-btn
                    small
                    plain
                    elevation="0"
                    route
                    to="/public-kek"
                    @click="menuInfrastuktur = false"
                    v-if="currentUser === null"
                  >
                    <span>{{ $t("kawasanEkonomiKhusus") }}</span>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>

              <v-list-item>
                <v-icon color="red">mdi-factory</v-icon>
                <v-list-item-action>
                  <v-list-item-title>
                    <v-btn
                      small
                      plain
                      elevation="0"
                      route
                      to="/public-kpi"
                      @click="menuInfrastuktur = false"
                      v-if="currentUser === null"
                    >
                      <span>{{ $t("kawasanPeruntukanIndustri") }}</span>
                    </v-btn>
                  </v-list-item-title>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item>
                <v-icon color="red">mdi-flash</v-icon>
                <v-list-item-action>
                  <v-list-item-title>
                    <v-btn
                        small
                        plain
                        elevation="0"
                        route
                        to="/public-ebt"
                        @click="menuInfrastuktur = false"
                        v-if="currentUser === null"
                    >
                      <span>Energy</span>
                    </v-btn>
                  </v-list-item-title>
                </v-list-item-action>
              </v-list-item>

            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar-items>
      <v-toolbar-items class="hidden-sm-and-down row--dense">
        <v-btn
          small
          plain
          elevation="0"
          color="white darken-1"
          route
          to="/public-insentif"
          v-if="currentUser === null"
        >
          <span class="font-weight-bold caption">{{ $t("insentif") }}</span>
        </v-btn>
      </v-toolbar-items>

      <v-toolbar-items class="hidden-sm-and-down row--dense">
        <v-btn
            small
            plain
            elevation="0"
            color="white darken-1"
            route
            @click="goToWebinar"
            v-if="currentUser === null"
        >
          <span class="font-weight-bold subtitle-2 teks-effect-toright ">Event-Webinar</span>
        </v-btn>
      </v-toolbar-items>

      <v-toolbar-items class="mx-0">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              v-bind="attrs"
              v-on="on"
              rounded
              icon
              class="px-5"
              x-small
            >
              <v-icon>mdi-translate</v-icon>
              <!--              {{ lang }}-->
              <v-avatar size="18" class="elevation-0">
                <v-img width="20" :src="getFlagImage(lang)"></v-img>
              </v-avatar>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in menu_language"
              :key="index"
              dense
            >
              <v-list-item-title>
                <v-avatar size="22" class="elevation-1">
                  <v-img :src="getFlagImage(item.id)"></v-img>
                </v-avatar>
                <v-btn
                  small
                  plain
                  elevation="0"
                  @click="languageChange(item.id)"
                >
                  {{ item.description }}
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>

    <v-navigation-drawer
      app
      class="bottom-gradient hidden-md-and-up"
      v-model="drawer"
    >
      <v-layout column align-center>
        <v-flex class="mt-5">
          <v-img
            lazy-src="../assets/images/logo_sidebar_small.png"
            max-height="200"
            max-width="200"
            src="../assets/images/logo_sidebar_small.png"
          ></v-img>
        </v-flex>
      </v-layout>

      <v-divider></v-divider>

      <v-list>
        <v-list-item route to="/public-ipro">
          <v-list-item-icon>
            <v-icon color="indigo">mdi-diamond</v-icon>
          </v-list-item-icon>
          <v-list-item-title>IPRO</v-list-item-title>
        </v-list-item>
        <v-list-item route to="/public-peluang">
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("peluangInvestasi") }}</v-list-item-title>
        </v-list-item>
        <v-list-item route to="/public-potensi">
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("potensi") }}</v-list-item-title>
        </v-list-item>
        <v-list-item route to="/public-komoditi">
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("komoditas") }}</v-list-item-title>
        </v-list-item>
        <v-list-item route to="/public-profil-daerah">
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("profilDaerah") }}</v-list-item-title>
        </v-list-item>

        <v-list-item route to="/public-infrastruktur">
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("infrastruktur") }}</v-list-item-title>
        </v-list-item>

        <v-list-item route to="/public-energy">
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
          <v-list-item-title>Energy</v-list-item-title>
        </v-list-item>

        <v-list-item route to="/public-insentif">
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("insentif") }}</v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item route to="/public-ki">
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("kawasanIndustri") }}</v-list-item-title>
        </v-list-item>
        <v-list-item route to="/public-kih">
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            $t("kawasanIndustriHalal")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item route to="/public-kek">
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            $t("kawasanEkonomiKhusus")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item route to="/public-kpi">
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            $t("kawasanPeruntukanIndustri")
          }}</v-list-item-title>
        </v-list-item>

        <v-list-item route to="/public-ebt">
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
          <v-list-item-title>
              Energy
          </v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item @click="goToWebinar">
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <span class="pink--text">Event-Webinar</span>
          </v-list-item-title>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import FileService from "@/services/apiservices/file-service";
import CountryMixin from "@/plugins/CountryMixin";

export default {
  mixins: [CountryMixin],
  name: "NavBar",
  data() {
    return {
      drawer: false,
      menuInfrastuktur: false,

      menu_berita_agenda: [
        { icon: "", title: "Berita", route: "/public_berita", visible: true },
        { icon: "", title: "Agenda", route: "/public_agenda", visible: true },
      ],
      menu_informasi: [
        {
          icon: "",
          title: "Tenaga Kerja",
          route: "/public_tenaga_kerja",
          visible: true,
        },
        {
          icon: "",
          title: "Kontraktor",
          route: "/public_cv_pt",
          visible: true,
        },
      ],
      menu_language: [
        { id: "en", description: "English", icon: "flag_en.jpg" },
        { id: "id", description: "Indonesia", icon: "flag_id.png" },
      ],
    };
  },
  computed: {
    lang: {
      get() {
        return this.$store.state.langModule.lang;
      },
      set(val) {
        this.$store.dispatch("langModule/setLang", val);
      },
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    getFlagImage(fileName) {
      const lang = fileName;
      if (lang === undefined) {
        return require("@/assets/i18n/flag_id.jpg");
      } else {
        const langJson = this.menu_language.filter(
          (item) => item.id === `${lang}`
        );
        return require("@/assets/i18n/" + langJson[0].icon);
      }
    },

    languageChange(value) {
      this.lang = value;
      this.$i18n.locale = this.lang;
    },


    goToWebinar() {
      window.open('https://webinar-jatimprov.des-green.com/', '_blank');
    },

    lookupImageUrl(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/account_icon.png";
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },
    lookupImageUrlLazy() {
      return "./assets/images/account_icon.png";
    },
  },
  mounted() {
    console.log(this.country);
  },
};
</script>


<style scoped>
.mytext-style1 {
  font-family: "Times New Roman";
}
.x1-small-line-height {
  line-height: 1.4em;
}
.x2-small-line-height {
  line-height: 0.6em;
}
.x-small-line-height {
  line-height: 0.8em;
}
.small-line-height {
  line-height: 1.1em;
}

myLink {
  background-image: linear-gradient(to right, #54b3d6, #54b3d6 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

myLink:before {
  content: "";
  background: #54b3d6;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

myLink:hover {
  background-position: 0;
}

myLink:hover::before {
  width: 100%;
}

.my-font-pointjatim {
  font-family: "Arial Rounded MT Bold";
  font-weight: bolder;
  font-size: 29px;
}
.my-font-potential {
  font-family: "Arial";
  font-weight: bold;
  font-size: 9px;
}


.teks-effect-toright {
  font-size: 0px;
  font-weight: bolder;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-align: center;

  background: linear-gradient(to right, #4ac050, #2196f3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 5s ease infinite;

  text-transform: uppercase;
  background-image: linear-gradient(
      -225deg,
      #ffffff 0%,
      #4e1982 29%,
      #ff1361 67%,
      #fff800 100%
  );

  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 38px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.waviy span {
  position: relative;
  display: inline-block;
  font-size: 40px;
  color: #0f2270;
  text-transform: uppercase;
  animation: flip 2s infinite;
  animation-delay: calc(0.2s * var(--i));
}
@keyframes flip {
  0%,
  80% {
    transform: rotateY(360deg);
  }
}


</style>