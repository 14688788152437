<template>

  <v-card class="mt-2 ml-2 mr-2">
    <FDayaDukungTable ref="refTabDayaDukungClick"/>
  </v-card>

</template>

<script>
import FDayaDukungTable from "../../components/admin-setup/din-carousel/FDinCarouselTable.vue";

export default {
  components: { FDayaDukungTable },
  data() {
    return {
      firstTabTitle: 'DAYA DUKUNG',
      firstTabIcon: 'mdi-factory'
    }
  },
  methods: {
    tabDayaDukungClick(){
      try {
        this.$refs.refTabDayaDukungClick.fetchParent()
      }catch (e) {
        e.toString()
      }
    },

  }
}
</script>

<style scoped>

</style>