import axios from 'axios';
import authHeader from "../auth-header";
import ConstApiUrls from "../const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FInfrastructureService {
    getApiUrl() {
        return API_URL
    }
    getAllFInfrastructureByTypePublic(typeBean){
        return axios.get(API_URL + `public/getAllFInfrastructureByTypePublic/${typeBean}`, { headers: authHeader() });
    }
    getAllFInfrastructureByTypePublicSimple(typesBean){
        return axios.get(API_URL + `public/getAllFInfrastructureByTypePublicSimple/${typesBean}`, { headers: authHeader() });
    }
    getPostAllFInfrastructureByTypesPublicSimple(typesBean){
        return axios.post(API_URL + `public/getPostAllFInfrastructureByTypesPublicSimple`, typesBean, { headers: authHeader() });
    }

    getAllFInfrastructureByCityPublic(city){
        return axios.get(API_URL + `public/getAllFInfrastructureByCityPublic/${city}`, { headers: authHeader() });
    }

    getAllFInfrastructureByTypesPublic(city, finfrastructureTypeIds){
        return axios.get(API_URL + `public/getAllFInfrastructureByTypesPublic/${finfrastructureTypeIds}`, { headers: authHeader() });
    }
    getAllFInfrastructureByTypesAndFareaBeanPublic(finfrastructureTypeIds, fareaBean){
        return axios.get(API_URL + `public/getAllFInfrastructureByTypesAndFareaBeanPublic/${finfrastructureTypeIds}/${fareaBean}`, { headers: authHeader() });
    }

    getAllFInfrastructureByCityAndInfrastructurePublic(city, finfrastructureTypeIds){
        // console.log(city)
        if (! city) city = ' '
        return axios.get(API_URL + `public/getAllFInfrastructureByCityAndInfrastructurePublic/${city}/${finfrastructureTypeIds}`, { headers: authHeader() });
    }
    getAllFInfrastructureByFareaPublic(fAreaBean){
        return axios.get(API_URL + `public/getAllFInfrastructureByFareaPublic/${fAreaBean}`, { headers: authHeader() });
    }

    getAllFInfrastructure(){
        return axios.get(API_URL + `getAllFInfrastructure`, { headers: authHeader() });
    }

    getAllFInfrastructureByOrgLevel(){
        return axios.get(API_URL + `getAllFInfrastructureByOrgLevel`, { headers: authHeader() });
    }

    getAllFInfrastructureContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFInfrastructure`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFInfrastructureContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getPostAllFInfrastructureContainingExt(item){
        // console.log(JSON.stringify(item))
        return axios.post(API_URL + `getPostAllFInfrastructureContainingExt`, item, { headers: authHeader() });
    }

    getFInfrastructureById(id){
        return axios.get(API_URL + `getFInfrastructureById/${id}`, { headers: authHeader() });
    }
    updateFInfrastructure(item){
        return axios.put(API_URL + `updateFInfrastructure/${item.id}`, item, {headers: authHeader()})
    }
    createFInfrastructure(item){
        return axios.post(API_URL + `createFInfrastructure`, item, {headers: authHeader()})
    }
    createFInfrastructureMultiple(items){
        return axios.post(API_URL + `createFInfrastructureMultiple`, items, {headers: authHeader()})
    }
    deleteFInfrastructure(id){
        return axios.delete(API_URL + `deleteFInfrastructure/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFInfrastructure(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFInfrastructure`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFInfrastructure`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FInfrastructureService()