export default class FDinCarousel {
  constructor(
    id,
    kode1="",
    description ="",
    fdivisionBean =0,
    statusActive =true,
    baseId,
    lang,

    fdinCarouselTypeBean =0,
    
  ) {
    this.id = id;
    this.kode1 = kode1;
    this.description = description;
    this.statusActive = statusActive;
    this.fdivisionBean = fdivisionBean;
    this.statusActive = statusActive;
    this.baseId = baseId;
    this.lang = lang;

    this.fdinCarouselTypeBean = fdinCarouselTypeBean;
  }

}
