const EOrganizationLevel = Object.freeze({
    SYS : "SYS",
    CORP: "CORP",
    DIV: "DIV",
})
const EOrganizationLevels = Object.freeze([
    {
        id: EOrganizationLevel.SYS,
        description: 'SYS (Holding)'
    },
    {
        id: EOrganizationLevel.CORP,
        description: 'CORP (Company)'
    },
    {
        id: EOrganizationLevel.DIV,
        description: 'DIV (Pemilik Data)'
    },

])
export  {EOrganizationLevel as default, EOrganizationLevels}